<template>
  <div>
    <breadcrumb-bar
      :title="appTitle"
      :breadcrumbs="breadcrumbs"
    />
    <v-container fluid>
      <v-row>
        <v-col cols="12">
          <productListings
            :add-enabled="enableAdd"
            :title="this.$t('titleProductListings')"
          />
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  import { mapFields } from 'vuex-map-fields'
  import verifyAPI from '@/authentication/authorization'
  export default {
    Name: 'Listings',
    components: {
      productListings: () => import('@/views/components/products/ProductListings'),
      BreadcrumbBar: () => import('@/views/components/core/BreadcrumbBar'),
    },
    data: function () {
      return {
        enableAdd: false,
        breadcrumbs: [
          {
            text: this.$t('bcHome'),
            disabled: false,
            href: '/dashboard',
          },
          {
            text: this.$t('bcSettings'),
            disabled: true,
            href: '/settings',
          },
          {
            text: this.$t('bcListings'),
            disabled: true,
            href: '/settings/listings',
          },
        ],
      }
    },
    computed: {
      ...mapFields({
        currentUser: 'currentUser',
      }),
      appTitle: function () {
        if (this.currentUser != null) {
          return this.currentUser.partnerName + ' - ' + this.$t('titleProductListing')
        } else {
          return this.$t('titleProductListing')
        }
      },
    },
    mounted: function () {
      if (verifyAPI.isAuthorized(this.currentUser, 3)) {
        this.enableAdd = true
      }
    },
  }
</script>
